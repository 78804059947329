<script>
import BaseSummary from './BaseSummary';
export default {
  data: (vm) => ({
    base_url: "/backend/lands/summary/",
    mongo_url: "/mongo/land_transcripts/",
    export_url:"/backend/lands/export/",
  }),
  extends: BaseSummary,

};
</script>
