<template>
  <v-container id="Land-profile-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      title="Base Summary"
      class="px-5 py-3"
    >
      <CDFilter v-on:onCDRFilterChange="onCDRFilterChange" ref="childRef">
      </CDFilter>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        dense
      >
      </v-data-table>
    </base-material-card>
  </v-container>
</template>
<script>
import CDFilter from "../filters/CDFilter";
import { mapState, mapMutations, mapActions } from "vuex";
import { city_info } from "@/definition.js";
export default {
  data: (vm) => ({
    dialog_form: false,
    filterItem: {
      city__code: "H",
      district__code: null,
      region__code: null,
      lbkey: null,
      owners: null,
      creditors: null,
      c_add_private: null,
      c_add_p_num: null,
      create_time: {},
    },
    editedIndex: -1,
    action: "",
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("owners_range"),
        value: "name",
      },
      {
        sortable: false,
        text: vm.$i18n.t("owners_count"),
        value: "owners_count",
      },
      {
        sortable: false,
        text: vm.$i18n.t("creditors_count"),
        value: "creditors_count",
      },
    ],
    city_info: city_info,
    district_info: [],
    region_info: [],
  }),
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  components: {
    CDFilter,
  },
  computed: {
    ...mapState(["city", "district", "region", "token"]),
  },
  methods: {
    ...mapMutations(["setCity", "setRegion"]),

    getApi() {
      console.log("call me....");
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + this.base_url;
      console.log("what...url " + url);

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };
      for (const [key, value] of Object.entries(this.filterItem)) {
        if (!this.$common_util.isEmpty(value)) {
          config.params[key] = value;
        }
      }
      // get some fileds
      //   config.params["transcript__isnull"] = false;
      //   config.params["query"] = "{ id, city, district, region, lbkey, }";

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    postApi() {},
    getList(data) {
      return data;
    },
    getRegNo(data) {
      if (data === null || data === "") {
        return data;
      }
      let parseObj = JSON.parse(data.replace(/'/g, '"'));
      console.log(parseObj);
      let result = "";
      for (let i = 0; i < parseObj.length; i++) {
        result += parseObj[i] + ";";
      }

      return result;
    },
    setResult(data) {
      let info = JSON.parse(JSON.stringify(data));
      this.totalItems = data.count;
      this.items = data.results;
      const currentObj = this;
    },
    onCDRFilterChange(item) {
      console.log("onCDRFilterChange");
      this.filterItem = this._.cloneDeep(item);
      this.getApi();
    },
    getTranscriptJson(item, set_result_func = this.setTranscriptResult) {
      const currentObj = this;
      const url =
        process.env.VUE_APP_SERVER_URL + this.mongo_url + item.transcript + "/";
      console.log("url " + url);
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };
      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          set_result_func(response.data);
          currentObj.loading = false;
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },

    close() {
      this.transcript_show = false;
    },
    callFilterApply() {
      this.$refs.childRef.onChange();
    },
    exportInfo(item) {
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + this.export_url;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
  },
  mounted() {
    // this.$options.sockets.onmessage = this.receiveSocketData;
  },
};
</script>
