const city_info = [
  { text: "[A] 臺北市", value: "A" },
  { text: "[B] 臺中市", value: "B" },
  { text: "[C] 基隆市", value: "C" },
  { text: "[D] 臺南市", value: "D" },
  { text: "[E] 高雄市", value: "E" },
  { text: "[F] 新北市", value: "F" },
  { text: "[G] 宜蘭縣", value: "G" },
  { text: "[H] 桃園市", value: "H" },
  { text: "[I] 嘉義市", value: "I" },
  { text: "[J] 新竹縣", value: "J" },
  { text: "[K] 苗栗縣", value: "K" },
  { text: "[M] 南投縣", value: "M" },
  { text: "[N] 彰化縣", value: "N" },
  { text: "[O] 新竹市", value: "O" },
  { text: "[P] 雲林縣", value: "P" },
  { text: "[Q] 嘉義縣", value: "Q" },
  { text: "[T] 屏東縣", value: "T" },
  { text: "[U] 花蓮縣", value: "U" },
  { text: "[V] 臺東縣", value: "V" },
  // { text: "[W] 金門縣", value: "W" },
  // { text: "[X] 澎湖縣", value: "X" },
  // { text: "[Z] 連江縣", value: "Z" },
];

const lbtype_option = [
  {
    text: "Land",
    value: "L",
  },
  {
    text: "Building",
    value: "B",
  },
];

const systemOption = [
  {
    text: "Quanta",
    value: 0,
  },
  {
    text: "Cop",
    value: 1,
  },
  {
    text: "EPLand",
    value: 2,
  },
  {
    text: "Gaias",
    value: 3,
  },
];

const oc_option = [
  {
    text: "All",
    value: 0,
  },
  {
    text: "Owner",
    value: 1,
  },
  {
    text: "Creditor",
    value: 2,
  },
  {
    text: "Mark",
    value: 3,
  },
];

const radioOption = [
  {
    text: "list",
    value: 0,
  },
  {
    text: "tp",
    value: 1,
  },
  {
    text: "change_type",
    value: 2,
  },
  {
    text: "full_transcript",
    value: 5,
  },
];

const mission_action = [
  {
    text: "List",
    value: 0,
  },
  {
    text: "Transcript",
    value: 1,
  },
  {
    text: "Normal",
    value: 2,
  },
  {
    text: "FullTranscript",
    value: 5,
  },
];

const manual_radioOption = [
  {
    text: "list_only",
    value: 1,
  },
  {
    text: "tp_only",
    value: 2,
  },
];

const status_items = [
  {
    text: "Init",
    value: 0,
  },
  {
    text: "InPool",
    value: 1,
  },
  {
    text: "Packed",
    value: 2,
  },
  {
    text: "Processing",
    value: 3,
  },
  {
    text: "Suspend",
    value: 4,
  },
  {
    text: "Done",
    value: 9,
  },
  {
    text: "Fail",
    value: 10,
  },
  {
    text: "Cancel",
    value: 11,
  },
  {
    text: "Invalid",
    value: 12,
  },
  {
    text: "OverDue",
    value: 13,
  },
  {
    text: "KeyInvalid",
    value: 14,
  },
];

const action_type = [
  {
    text: "列表",
    value: 0,
  },
  {
    text: "謄本",
    value: 1,
  },
  {
    text: "混合",
    value: 2,
  },
  {
    text: "完整謄本",
    value: 5,
  },
  {
    text: "Cop (所有權新增)",
    value: 18,
  },
  {
    text: "Cop (他項)",
    value: 19,
  },
  {
    text: "OTP單一謄本",
    value: 30,
  },
  {
    text: "光特列表",
    value: 10,
  }
];

const action_type_enum =
{
  List: 0,
  Transcript: 1,
  Mixed: 2,
  FullTranscript: 5,
  OwnerDiff: 7,
  CopOwnerDiff: 18,
  CopCreditorDiff: 19,
  GetPrivateSetting: 20,
}
const action_type_enum_str = [
  { text: "未知", value: null },
  { text: "查詢", value: action_type_enum.List },
  { text: "謄本", value: action_type_enum.Transcript },
  { text: "混合", value: action_type_enum.Mixed },
  { text: "完整謄本", value: action_type_enum.FullTranscript },
  { text: "逢甲所有", value: action_type_enum.CopOwnerDiff },
  { text: "逢甲他項", value: action_type_enum.CopCreditorDiff },
]

const system_type_enum =
{
  QuantaSoft: 0,
  COP: 1,
  EPLand: 2,
  Gaias: 3,
}

const system_type_enum_str = [
  { text: "QuantaSoft", value: system_type_enum.QuantaSoft },
  { text: "COP", value: system_type_enum.COP },
  { text: "EPLand", value: system_type_enum.EPLand },
  { text: "Gaias", value: system_type_enum.Gaias },
]

const spider_status_enum =
{
  Unknown: 0,
  Online: 1,
  Suspend: 2,
}

const spider_status_array =
  [
    {
      text: "Unknown",
      value: 0,
    },
    {
      text: "Online",
      value: 1,
    },
    {
      text: "Suspend",
      value: 2,
    },
  ]

const account_type =
  [
    {
      text: "System",
      value: 0,
    },
    {
      text: "QueryOnly",
      value: 1,
    },
    {
      text: "Customer",
      value: 2,
    },
    {
      text: "Developer",
      value: 3,
    },

    {
      text: "OwnerDiff",
      value: 14,
    },
    {
      text: "UnderChecking",
      value: 15,
    },
  ]


const schedule_status =
  [
    {
      text: "Not Completed",
      value: -1,
    },
    {
      text: "Init",
      value: 0,
    },
    {
      text: "Ongoing",
      value: 1,
    },
    {
      text: "Done",
      value: 2,
    },
    {
      text: "Fail",
      value: 3,
    },
    {
      text: "Suspend",
      value: 4,
    },
    {
      text: "Verifying",
      value: 9,
    },
    {
      text: "Reported",
      value: 10,
    },
  ]

const task_status_enum = {
  Init: 0,
  InPool: 1,
  Packed: 2,
  Processing: 3,
  Suspend: 4,
  Done: 9,
  Fail: 10,
  Cancel: 11,
  Invalid: 12,
  KeyInvalid: 14,
}

const schedule_filter =
  [
    {
      text: "Init",
      value: 0,
    },
    {
      text: "Ongoing",
      value: 1,
    },
    {
      text: "Done",
      value: 2,
    },
    {
      text: "Fail",
      value: 3,
    },
    {
      text: "Suspend",
      value: 4,
    },
    {
      text: "NotDefine",
      value: 5,
      disable: 1
    },
    {
      text: "NotDefine",
      value: 6,
      disable: 1
    },
    {
      text: "NotDefine",
      value: 7,
      disable: 1
    },
    {
      text: "NotDefine",
      value: 8,
      disable: 1
    },
    {
      text: "Verifying",
      value: 9,
      disable: 1,
    },
    {
      text: "Reported",
      value: 10,
    },
  ]

const change_types =
  [
    {
      text: "Not Detect",
      value: 0,
    },
    {
      text: "Owner Change",
      value: 1,
    },
    {
      text: "Creditor Changed",
      value: 2,
    },
    {
      text: "All changed",
      value: 3,
    },
    {
      text: "Owner Full",
      value: 4,
    },
    {
      text: "Latest",
      value: 5,
    },
  ]

const interval =
  [
    {
      text: "latest",
      value: 0,
    },
    {
      text: "3 days",
      value: 3,
    },
    {
      text: "Weeks",
      value: 7,
    },
  ]


// class CustomerStatus(EnumBase):
//   Init = 0
//   Ongoing = 1
//   Done = 2
//   Fail = 3
//   Suspend = 4
//   Invalid = 10
const customer_status =
  [
    {
      text: "Init",
      value: 0,
    },
    {
      text: "Ongoing",
      value: 1,
    },
    {
      text: "Done",
      value: 2,
    },
    {
      text: "Fail",
      value: 3,
    },
    {
      text: "Suspend",
      value: 4,
    },
    {
      text: "Suspend",
      value: 10,
    },
  ]

// class ScheduleV3Type(IntEnum):
// PrivateDetect = 900  # 私設偵測 COP/EPLAND/光特:2
// QueryList = 901  # 查詢清單
// FullTranscript = 902  # 全謄本
// OwnerChangeDetect =  903 # 偵測使用者異動
const schedule_v3_type = {
  PrivateDetect: 900,
  QueryList: 901,
  FullTranscript: 902,
  OwnerChangeDetect: 903,
}

const schedule_v3_type_str = [
  { text: "私設偵測", value: schedule_v3_type.PrivateDetect },
  { text: "查詢清單", value: schedule_v3_type.QueryList },
  { text: "全謄本", value: schedule_v3_type.FullTranscript },
  { text: "所有權異動", value: schedule_v3_type.OwnerChangeDetect },
]

// class ScheduleStatus(EnumBase):
// Unknown = -1
// Init = 0
// Ongoing = 1
// Done = 2
// Fail = 3
// Suspend = 4
const schedule_v3_status = {
  Unknown: -1,
  Init: 0,
  Ongoing: 1,
  Done: 2,
  Fail: 3,
  Suspend: 4,
}

const schedule_v3_status_str = [
  { text: "未知", value: schedule_v3_status.Unknown },
  { text: "初始", value: schedule_v3_status.Init },
  { text: "進行中", value: schedule_v3_status.Ongoing },
  { text: "完成", value: schedule_v3_status.Done },
  { text: "失敗", value: schedule_v3_status.Fail },
  { text: "暫停", value: schedule_v3_status.Suspend },
]


export {
  city_info,
  systemOption,
  oc_option,
  radioOption,
  manual_radioOption,
  status_items,
  action_type,
  action_type_enum,
  action_type_enum_str,
  system_type_enum,
  system_type_enum_str,
  change_types,
  lbtype_option,
  account_type,
  schedule_status,
  interval,
  customer_status,
  schedule_filter,
  mission_action,
  spider_status_enum,
  spider_status_array,
  schedule_v3_type,
  schedule_v3_type_str,
  schedule_v3_status,
  schedule_v3_status_str,
  task_status_enum,
};
